<!-- <div class="center" *ngIf="isHome == 1">
  <img class="center" src="../../../../assets/images/logo/background_dentbox.png" alt="background-image">
  <h2 class="heading-main">Get Connected with
    <a href="#">DentBox</a>
  </h2>
  <p class="sub-heading-main">Get DentBox Client to stay Connected with your Lab to keep in track with your things.</p>
</div> -->
<div class="row chat-heading ch-heading" [ngClass]="hasProvidedWork ? 'w-max': 'col-xs-10 col-sm-8 col-md-8 col-lg-10'">

  <div class=" heading-avatar col-xs-9 col-sm-7 col-md-7 col-lg-7">
    <div [hidden]="work == null || work == undefined || work._id == null || work._id == undefined">
      <div class="heading-avatar-icon">
        <div class="img-left">
          <img *ngIf="work.profileImagePath" src="{{ work.profileImagePath }}" alt="profile-image-path">
        </div>
        <a class="right-bar-toggle waves-effect waves-light">
          <label class="no-margin" [ngClass]="[
        work.state === 'active' ? 'active-avatar' : '',
        work.state === 'pending' ? 'pending-avatar' : '',
        work.state === 'closed' ? 'closed-avatar' : '']" style="border-radius: 50%;"
            *ngIf="!work.profileImagePath"></label>
        </a>
      </div>
      <div class="heading-name" *ngIf="clinicCode === undefined">
        <a class="heading-name-meta h-name-new" *ngIf="isHidePatient == false">{{ work.patientName + ' ' +
          work.patientSurname }}
          <span class="id-name">{{ work.patientHistoryNumber }}</span>
        </a>
        <a class="heading-name-meta h-name-new" *ngIf="isHidePatient === true">
          <span class="id-name">{{ work.patientHistoryNumber }}</span>
        </a>
        <small class="type-pf-work">{{ work.description }}</small>
        <span class="heading-online">{{ work.state }}</span>
      </div>
      <div class="heading-name" *ngIf="clinicCode !== undefined">
        <a class="heading-name-meta h-name-new">{{ work.patientName + ' ' + work.patientSurname }}
          <span class="id-name">{{ work.patientHistoryNumber }}</span>
        </a>
        <small class="type-pf-work">{{ work.description }}</small>
        <span class="heading-online">{{ work.state }}</span>
      </div>

    </div>
  </div>
  <div class="col-xs-3 col-sm-5 col-md-5 col-lg-5">
    <div class="text-right version-icon">
      <label [ngbPopover]="version" popoverClass="popover-lab" triggers="mouseenter:mouseleave" placement="bottom-right" class="mb-0">
        <i class="fa fa-question-circle" aria-hidden="true"></i>
      </label>
      <ng-template #version>
        <label>{{'resource.product_version' | translate}}: {{ appVersion }}</label>
      </ng-template>
    </div>
    <div [hidden]="work == null || work == undefined || work._id == null || work._id == undefined" class=" heading-dot">
      <ul class="nav navbar-nav navbar-right pull-right">
        <li>
          <select [(ngModel)]="work.state" (change)="OnChangeWorkStatus()"
            [ngStyle]="{'background': (work.state === 'pending') ? '#F57C00': (work.state === 'active') ? '#388E3C' : (work.state === 'closed') ? '#607D8B': ''}">
            <option [disabled]="user_type === 'clinic'" value="active">{{ 'resource.active' | translate }}</option>
            <option [disabled]="user_type === 'lab'" value="closed">{{ 'resource.closed' | translate }}</option>
            <option value="pending">{{ 'resource.pending' | translate }}</option>
          </select>
        </li>
        <li class="floatstyle">
          <label *ngIf="work.entryDate != undefined" class="lblstyle" data-toggle="tooltip" data-placement="left"
            title="{{ 'resource.entryDate' | translate }}">
            <i class="fa fa-angle-down m-r-5" aria-hidden="true"></i>{{ (work.entryDate) | date: 'dd-MM-yyyy' }}</label>
          <label *ngIf="work.deliveryDate != undefined" class="lblstyle" data-toggle="tooltip" data-placement="left"
            title="{{ 'resource.deliverDate' | translate }}">
            <i class="fa fa-angle-up m-r-5" aria-hidden="true"></i>{{ (work.deliveryDate) | date: 'dd-MM-yyyy'
            }}</label>
        </li>
        <li class="hamberger-rightbar">
          <i class="fa fa-bars  pull-right right-bar-toggle waves-effect waves-light" aria-hidden="true"></i>
        </li>
      </ul>
    </div>
  </div>
 
</div>
<!-- Heading End -->